








  import {
    Component,
    Prop,
    Vue
  } from "vue-property-decorator";
  import * as qiniu from 'qiniu-js'
import { api } from "@/api";

  @Component({
    name: 'global-qiniu',
  })
  export default class GlobalQiniu extends Vue {
    @Prop({default: 'image'}) private fileType!: string
    @Prop({default: false}) private isMultiple!: boolean
    private token: string = ''
    private loading: any = null

    /**
     * @func 点击
     */
    private handleClick() {
      this.$nextTick(() => {
        const refs: any = this.$refs['upload'+this.fileType];
        if (refs) {
          refs.click();
          this.getToken()
        }
      })
    }

    /**
     * @func 获取七牛token
     */
    private async getToken() {
      try{
        const res: any = await api.getQiniuToken({})
        this.token = res.token
      } catch(err) {
        console.log(err)
      }
    }

    /**
     * @func 获取选择的文件
     * @param file
     */
    private selectPhoto(file: any) {
      if (file.target.files.length > 9) {
        this.$weui.topTips('上传文件不能超9个')
        return
      }
      for (let i = 0; i < file.target.files.length; i++) {
        this.uploadFile(file.target.files[i])
      }
      file.target.value = ''
    }

    /**
     * @func 图片上传
     */
    private uploadFile(file: any) {
      this.loading= this.$weui.loading('上传中...')
      const domain = "https://oss.guojiangmedia.com/"
      const nowTime = new Date().getTime()
      const fileKey = nowTime + Math.round(Math.random() * 10000) + '.' + file.type.split('/')[1]
      const config: any = {
        useCdnDomain: true,
        region: qiniu.region.z0
      }

      const putExtra: any = {
        fname: '',
        params: {},
        mimeType: ''
      }

      let observable = qiniu.upload(file, fileKey, this.token, putExtra, config);
      observable.subscribe({
        next: (result: any) => {
          // console.log(result.total.percent, 100)
          if (result.total.percent === 100) {
            this.loading.hide()
          }
        },
        error: (errResult) => {
          console.log(errResult);
          this.$weui.topTips('提交失败')
          this.loading.hide()
        },
        complete: (result) => {
          console.log(domain + result.key)
          this.$emit('success', domain + result.key)
        }
      })
    }


    private beforeDestroy() {
      this.loading.hide()
    }
  }
